import React from 'react'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeTwo from "../../components/layout/sidemenu/sidemenu_se_two"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit2Icon from '../../images/icons/unit_02_icon.svg'


const MovingCarbon21 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 2.1 Moving Carbon" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeTwo location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'2.1 Moving Carbon'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit2Icon }
                iconAlt="Unit 2 icon"
                studentPageHeader="Unit 1: Carbon Now"
                unitNumber="2.1"
                studentPageHeaderSub="Moving Carbon"
                sectionA={ <>Do You Have Carbon Breath?!</> }
                sectionALink="/unit-2/2.1-moving-carbon/#do-you-have-carbon-to-breath"
                sectionB={ <>Carbon on the Move</> }
                sectionBLink="/unit-2/2.1-moving-carbon/#carbon-on-the-move"
                sectionC={ <>Reflect and Connect</> }
                sectionCLink="/unit-2/2.1-moving-carbon/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>What are ways to monitor and model the movement of carbon in the Earth system?</> }
              />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Introduction</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row>
                        <Col>
                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid image-bordered"
                                fluid={props.data.image5.childImageSharp.fluid}
                                alt="This instrument monitors CO2 moving between the forest and the atmosphere above."
                              />
                            </Col>
                          </Row>
                          <Row className="d-md-none">
                            <Col>
                              <div className="image-caption image-caption-bordered rounded img-fluid mb-3 image-bordered">
                                This instrument monitors CO<sub>2</sub> moving between the forest and the atmosphere above.
                              </div>
                            </Col>
                          </Row>
                          <div className="smallImageRight">
                            <Img
                              className="image image-bordered ml-2 img-fluid d-none d-md-block"
                              fluid={props.data.image5.childImageSharp.fluid}
                              alt="This instrument monitors CO2 moving between the forest and the atmosphere above."
                            />
                            <div className="image-caption image-caption-bordered ml-2 mb-2 d-md-block d-none">
                              This instrument monitors CO<sub>2</sub> moving between the forest and the atmosphere above.
                            </div>
                          </div>

                          <p>
                            You have been studying the carbon cycle and how this relates to climate. You used some models to start to understand how carbon in CO<sub>2</sub> moves between the atmosphere and oceans. But you also know that there must be more specific connections between the carbon cycle and the biosphere. The <strong>biosphere</strong> is all the animals, plants, and other living organisms on Earth. This includes you! Studying these forms of carbon helps you learn more about <em>how</em> and <em>why</em> carbon cycling occurs on Earth. This is the focus of Unit 2. In Lesson 2.1, you will learn that:
                          </p>

                          <ul>
                            <li>Different processes move carbon among different parts of the Earth system.</li>
                            <li>You can use a box diagram to model how carbon moves in the Earth system.</li>
                            <li>Carbon transfer in the Earth system involves organisms in the biosphere.</li>
                            <li>Humans use a variety of technologies to monitor carbon moving in the Earth system.</li>
                          </ul>

                          <p>
                            Next, you'll do a set of quick activities that relate to carbon cycling and the biosphere.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="do-you-have-carbon-to-breath" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Do You Have Carbon Breath?!</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        In Unit 1, you used BTB indicator to "see" whether carbon was moving in or out of water. You know that you have carbon in your body, but do you have "carbon breath"?! Listen to your teacher as he or she explains an activity that you will do with a partner.
                      </p>

                      <ol>
                        <li>
                          Follow these steps to set up the investigation:
                            <ol type="a">
                              <li>Get one straw, one pair of safety goggles, and one test tube or small beaker for your team.</li>
                              <li>Your teacher will have a container of water with BTB solution added. It will be blue. Have one person add about 5 cm of water with BTB to your test tube or beaker.</li>
                              <li>Gently swirl the blue solution with one end of the straw. Without splashing, use the straw to gently mix in some air into the water with BTB. Does this change the color of the solution? Record what you observe.</li>
                            </ol>
                        </li>
                        <li>Choose one person to test whether he or she has "carbon breath." Have the person put on the safety goggles. Then, have the person cover the top of the container with a hand, and gently blow through the straw into the solution until the test is complete. Record what you see.</li>
                        <li>
                          Recall the two investigations you did in Lesson 1.1. The table below summarizes what you saw in those two investigations.
                          <ol type="a">
                            <li>Looking at the table, which investigation is most like what you did in steps 1 and 2 above?</li>
                            <li>From your investigations, does air or breath have more carbon?</li>
                            <li>If your breath had more carbon than the air you breathe in, discuss with your partner where that carbon might be coming from. Record your ideas in your science notebook.</li>
                          </ol>
                        </li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Investigation in Lesson 1.1</th>
                            <th className="table-active" scope="col">Color change with BTB in solution</th>
                            <th className="table-active" scope="col">Carbon movement</th>
                            <th className="table-active" scope="col">Change in acidity</th>
                            <th className="table-active" scope="col">Change in pH</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Seltzer water</td>
                            <td>Yellow &rarr; blue (or blue-green)</td>
                            <td>Carbon out of water; carbon decreases</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Dry ice (solid CO<sub>2</sub>) above water</td>
                            <td>Blue &rarr; yellow</td>
                            <td>Carbon into water; carbon increases</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <ol start="4">
                        <li>
                          In Lesson 1.1, you might have done an extra activity, "<Link to="unit-1/1.1-carbon-fizz#carbon-in-video"><em>Carbon Indicators&mdash;Responding to Reactions</em></Link>." A figure from that activity shows the relationship between the color of BTB indicator and acidity, or pH, of a solution. The figure is shown again here. Use the figure and the following steps to determine how change in carbon and color relate to changes in acidity.
                          <ol type="a">
                            <li>Copy the table above into your notebook.</li>
                            <li>From the table, complete the Change in acidity column. Add up or down arrows (&uarr; or &darr;) to show whether the color change for BTB indicates an increase or decrease in acidity.</li>
                            <li>Using the figure, complete the Change in pH column. Add up or down arrows (&uarr; or &darr;) to show whether the color change indicates an increase or decrease in pH value.</li>
                            <li>Summarize the relationship between acidity and pH values. Do they have a direct or indirect relationship?</li>
                          </ol>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image6.childImageSharp.fluid}
                            alt="pH scale bromothymol blue"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder"
                        fluid={props.data.image6.childImageSharp.fluid}
                        alt="pH scale bromothymol blue"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-on-the-move" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Carbon on the Move</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Processes in organisms and ecosystems on Earth are critical for learning about patterns of carbon cycling. In the last section, you explored one carbon-moving process for organisms like you. Did anyone have carbon breath? In the steps below, you'll explore other processes and settings where carbon moves.
                      </p>

                      <ol start="5">
                        <li>
                          You can explore carbon cycling by watching plants grow&mdash;literally. Even though you just see seeds and small plants, this will help you start thinking about a key process in the carbon cycle at the scale of Earth.
                          <ol type="a">
                            <li>
                              Watch the two videos of plants growing from seeds. <br />
                              <a href="http://www.edutube.org/video/time-lapse-radish-seeds-sprouting-roots-growing" target="_blank" rel="noopener noreferrer">
                                http://www.edutube.org/video/time-lapse-radish-seeds-sprouting-roots-growing&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                              </a>
                            </li>
                          </ol>
                        </li>
                      </ol>

                      <div id="radish-video" className="embed-container mb-3">
                        <iframe title="radish" className="videoSetting" src="https://player.vimeo.com/video/307869906" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <ol start="2" type="a">
                        <li>The mass of the plants (roots, stem, and leaves) at the end of the video is much greater than the mass of the seed at the beginning. Where do you think that the mass came from? Watch <em>both</em> videos to support your conclusion.</li>
                        <li>Plant cells contain different molecules. For example, the image below shows the structure for oil molecules in plants. The molecule is olive oil, which is obtained from the fruit of olive trees. What do you think is the source of all the carbon in this plant molecule?</li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-4 imageBorder"
                            fluid={props.data.image7.childImageSharp.fluid}
                            alt="Oleic acid"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder mb-4"
                        fluid={props.data.image7.childImageSharp.fluid}
                        alt="Oleic acid"
                      />

                      <ol start="6">
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={props.data.image8.childImageSharp.fluid}
                              alt="Dead leaf"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="smallImageRight img-fluid d-none d-md-block ml-3"
                          fluid={props.data.image8.childImageSharp.fluid}
                          alt="Dead leaf"
                        />

                        <li>
                          When leaves fall from trees in the forest, they gather on the forest floor.
                          <ol type="a">
                            <li>But what do you think happens to the leaves so that they do not get buried deeper and deeper?</li>
                            <li>Where does the carbon in the leaves go?</li>
                            <li>How does this process relate to the carbon cycle?</li>
                          </ol>
                        </li>
                        <li>
                          Forest floors typically have decaying leaves and other plant parts. Sometimes, however, the plant material is buried in a way that prevents it from decomposing and decaying.
                          <ol type="a">
                            <li>If this happened on a large scale, how do you think it could affect the CO<sub>2</sub> levels in the atmosphere?</li>
                            <li>After a long time, what do you think might happen to the leaves and plant parts that were not able to decay?</li>
                          </ol>
                        </li>
                        <li>
                          When plant matter burns, this also moves carbon. Look at the chemical reaction for burning wood or leaves. This reaction is called <em>combustion</em>. <br />
                          <strong>Combustion:</strong> <br />
                          C<sub>6</sub>H<sub>10</sub>O<sub>5</sub> + 6O<sub>2</sub> &rarr; 6CO<sub>2</sub> + 5H<sub>2</sub>O + thermal energy <br />
                          Molecule in Plant
                        </li>
                      </ol>

                      <p>
                        The video also shows combustion. Watch the video and then answer the questions about combustion.
                      </p>

                      <div className="embed-container mb-3">
                        <iframe title="combustion video" className="videoSetting mb-3" src="https://player.vimeo.com/video/307870756" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <ol start="8">
                        <ol type="a">
                          <li>What are the main elements in plant molecules?</li>
                          <li>What other molecule is needed for combustion?</li>
                          <li>
                            Complete the sentences below: <br />
                            <em>In a combustion reaction, carbon changes from &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; to &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em> <br />
                            <em>When the carbon changes to &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;, the carbon moves to &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em>
                          </li>
                        </ol>
                      </ol>

                      <ol id="step-9-lesson-2-1" start="9">
                        <li>Humans use technology to monitor how carbon moves at a global scale. For example, scientists at NASA monitor the levels of CO<sub>2</sub> in the atmosphere. They use a satellite with an instrument called the Atmospheric Infrared Sounder, or AIRS. Studying carbon in the atmosphere gives them a way to study Earth's carbon cycle. Watch the AIRS video and then answer the following questions.</li>
                      </ol>

                      <div className="embed-container mb-3">
                        <iframe title="airsco2" className="videoSetting" src="https://player.vimeo.com/video/327606251" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <ol>
                        <ol type="a">
                          <li>What do the colors indicate? Which color stands for the highest CO<sub>2</sub> levels?</li>
                          <li>Is CO<sub>2</sub> mixed evenly in the atmosphere?</li>
                          <li>What patterns do you see for CO<sub>2</sub> levels in the northern hemisphere over a year?</li>
                          <li>
                            What processes do you think could cause the CO<sub>2</sub> levels in the atmosphere to increase or decrease? <br />
                            <em>It's OK if you don't know the answer right now. You will learn some of these processes by the end of this unit!</em>
                          </li>
                        </ol>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image9.childImageSharp.fluid}
                            alt="Box model for carbon cycle"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight ml-2 mb-1 img-fluid d-none d-md-block imageBorder"
                        fluid={props.data.image9.childImageSharp.fluid}
                        alt="Box model for carbon cycle"
                      />

                      <p>
                        Your focus for Unit 2 is carbon in the present, and how carbon cycles around Earth. You have seen some examples of processes that move carbon. You have done this for test-tube-sized changes, as well as global changes, such as with the AIRS CO<sub>2</sub> data. In Unit 3, you will explore how these processes are a key to understanding climate. But first, complete the questions below with a partner. They will help you summarize carbon processes you will explore in Unit 2.
                      </p>

                      <ol start="10">
                        <li>
                          The figure shows a box diagram for parts of Earth with lots of carbon. The arrows show carbon moving between those parts.
                          <ol type="a">
                            <li>Copy the diagram to your notebook.</li>
                            <li>The table below shows investigations you have done with moving carbon in this lesson, as well as in Unit 1. Paste or copy the table into your notebook.</li>
                            <li>Enter your observations from the investigation into the "What You Saw" column.</li>
                            <li>Add the arrow number from the figure to the last column of the table.</li>
                          </ol>
                        </li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Your Carbon Investigation</th>
                            <th className="table-active" scope="col">What You Saw</th>
                            <th className="table-active" scope="col">Arrow Number in Box Diagram</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Breathing into water with BTB indicator</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Plants growing larger from seeds</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Leaves decaying and decomposing</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Burning of plant matter (combustion)</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>CO<sub>2</sub> levels increasing and decreasing in the atmosphere</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Unit 1: CO<sub>2</sub> from dry ice into water</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Unit 1: CO<sub>2</sub> out of seltzer water</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <ol start="11">
                        <li>
                          Recall your focus question for this activity. You are thinking more about how carbon moves in the Earth system in short periods.
                          <ol type="a">
                            <li>What are two ways that you, or humans in general, can monitor how carbon moves in the carbon cycle?</li>
                            <li>What are two ways you can use models to show how carbon moves in the carbon cycle?</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="2.2 Exploring Limits"
                NextLink="/unit-2/2.2-exploring-limits/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default MovingCarbon21

export const query = graphql`
  query {
    image5: file(relativePath: { eq: "student-pages/unit-2/05_instrument_co2_monitor.jpg" }) {
      ...rowImage
    }
    image6: file(relativePath: { eq: "student-pages/unit-2/06_ph_scale.jpg" }) {
      ...rowImage
    }
    image7: file(relativePath: { eq: "student-pages/unit-2/07_oleic_acid.png" }) {
      ...rowImage
    }
    image8: file(relativePath: { eq: "student-pages/unit-2/08_dead_leaf.jpg" }) {
      ...rowImage
    }
    image9: file(relativePath: { eq: "student-pages/unit-2/09_boxmodel_carboncycle.png" }) {
      ...rowImage
    }
  }
`
